import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)'
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {
      marginTop: theme.spacing(4)
    }
  })
);


const ManageRedemptionsView: React.FC = (props) => {
  const classes = useStyles();

  useEffect(() => {
    // Request Redemptions from DB
    return () => {

    };
  }, []);

  // Update redemption as completed
  const handleMarkComplete = (id: string, status: string) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log(status);
    };
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        Redemptions
        <div className={classes.flex} />
      </Toolbar>
      <Grid container justify='center'>
        <Grid item xs={12} md={10}>
          <Paper className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{new Date().toDateString()}</TableCell>
                  <TableCell>Test Giveaway</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>test@gmail.com</TableCell>
                  <TableCell>253-555-0122</TableCell>
                  <TableCell>pending</TableCell>
                  <TableCell>
                    <Button onClick={handleMarkComplete('1', 'pending')}>Mark Pending</Button>
                    <Button onClick={handleMarkComplete('1', 'complete')}>Mark Complete</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageRedemptionsView;
