import React, {createContext, useReducer, useEffect, useCallback} from 'react';
import {EntryDataSchema, EntryState, InitialEntryState, Reducer} from './reducer';
import { useAuthStore } from '../../hooks/useAuth';
import AxiosServer from '../../config/server';


interface ContextProps extends EntryState {
  setEntries: (data: { success: true; data: EntryDataSchema[] }) => void;
  fetchEntries: () => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const EntryStore = createContext({} as ContextProps);

export const EntryStoreProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialEntryState);
  const { isUserLoggedIn } = useAuthStore();

  const fetchEntries = useCallback(async () => {
    dispatch({ type: 'setLoading', payload: true });
    const { data } = await AxiosServer.get('/entries/history');
    setEntries(data);
  }, []);

  const setEntries = useCallback((response: { success: true; data: EntryDataSchema[] }) => {
    const data = response.data;
    dispatch({ type: 'setEntries', payload: data });
  }, []);


  useEffect(() => {
    if (isUserLoggedIn) {
      fetchEntries();
    }
  }, [isUserLoggedIn, fetchEntries]);


  const providerValue = {
    ...state,
    setEntries,
    fetchEntries
  };

  return (
    <EntryStore.Provider value={providerValue}>
      { children }
    </EntryStore.Provider>
  );
};
