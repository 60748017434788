import React, { useState } from 'react';
import {Card} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {useAuthStore} from '../../hooks/useAuth';
import Box from '@material-ui/core/Box';
import StyledButton from '../../components/StyledButton/StyledButton';
import Button from '@material-ui/core/Button';
import AxiosServer from '../../config/server';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import TitleComponent from '../../components/TextComponents/TitleComponent';
import StyledCard from '../../components/StyledCard/StyledCard';
import Typography from '@material-ui/core/Typography';
import UpdatePaymentMethodComponent from '../Account/UpdatePaymentMethodComponent';
import {CheckoutStoreProvider} from '../../hooks/useCheckout/context';
import {Elements, StripeProvider} from 'react-stripe-elements';


const SubscriptionStatusCard: React.FC = () => {
  const { user, fetchUser } = useAuthStore();
  const stripeProfile = user.stripeProfile;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      await AxiosServer.post('/payments/cancel-subscription');
      await fetchUser();
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant='h6'>Current Subscription Tier</Typography>
        <Typography variant='h4'>
          Tier { stripeProfile?.highestTier || 'Free' }
        </Typography>
        {
          stripeProfile?.active && (
            <>
              <Box py={2}>
                <Typography variant='h6'>Subscription { (!stripeProfile?.isCanceled ? 'Renewal' : 'Expiration') } Date</Typography>
                <Typography variant='h4'>{ stripeProfile?.subscriptionPeriodEnd && new Date(stripeProfile?.subscriptionPeriodEnd).toDateString() }</Typography>
              </Box>
              <StripeProvider apiKey={'' + process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                  <UpdatePaymentMethodComponent/>
                </Elements>
              </StripeProvider>
            </>
          )
        }
        <Box>
          { (stripeProfile && !stripeProfile?.isCanceled) && (<StyledButton onClick={handleCancelSubscription}>Cancel Subscription</StyledButton> ) }
          { (!stripeProfile || stripeProfile?.isCanceled) && (<Link to={'/subscribe'}><StyledButton variant='contained'>Subscribe</StyledButton></Link> )  }
        </Box>
        <Box>
          { isLoading ? <CircularProgress size={24} style={{ padding: 8 }}/> : null }
        </Box>
        <Typography variant='body2'>
          How to change plan(s): Go to <Link to={'/subscribe'}>Subscribe</Link> and resubscribe with your desired plans.<br/>
          If you change your plan during a billing cycle, entries will be awarded at the start of the next billing period.
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default SubscriptionStatusCard;
