import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import TitleComponent from '../TextComponents/TitleComponent';
import StyledButton from '../StyledButton/StyledButton';
import Button from '@material-ui/core/Button';
import AxiosServer from '../../config/server';
import {Typography} from '@material-ui/core';
import StyledCard from '../StyledCard/StyledCard';
import CircularProgress from '@material-ui/core/CircularProgress';

const DiscordCard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState<string>('');
  useEffect(() => {
    (async () => {
      try {
        const { data } = await AxiosServer.get('/users/discord');
        if (data) {
          setUsername(data.username);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }

    })();
  });

  const handleDelete = async () => {
    try {
      await AxiosServer.delete('/users/discord');
      setUsername('');
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenWindow = () => {
    window.open(process.env.REACT_APP_DISCORD_LINK,'_blank');
    return false;
  };


  return (
    <div>
      <StyledCard>
        <CardContent>
          { isLoading ? <Box textAlign='center'><CircularProgress size={50} style={{ padding: 8, textAlign: 'center' }}/></Box> :
            <>
              <Box mb={2}>
                <Typography variant='h6'>Status</Typography>
                <Typography variant='h4'>{ username === '' ? 'Not Linked' : 'Connected to the Yammie Noob Discord Server' }</Typography>
              </Box>
              <Box>
                { username === '' ?
                  <StyledButton onClick={handleOpenWindow}>Connect Discord</StyledButton>
                  : <StyledButton onClick={handleDelete}>Disconnect Account</StyledButton> }
              </Box>
              <Box mb={2}>
                { username === '' ? null
                  : <Typography variant='body2'>Disconnecting your account will remove you from the server</Typography> }
              </Box>
            </>
          }
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default DiscordCard;
