import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { SubscriptionTier } from '../../types/Subscription';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    closeContainer: {

    },
    close: {
      color: 'white',
      textAlign: 'right',
      '& .MuiIcon-root': {
        color: 'white'
      }
    },
    perksContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    perk: {
      alignItems: 'center',
      minWidth: '30%',

    }
  })
);

interface PlanItemProps {
  tier: SubscriptionTier;
  onRemove?: Function;
  currentPlan?: boolean;
}

const PlanItem: React.FC<PlanItemProps> = ({ tier, onRemove, currentPlan }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExtra = () => {
    setExpanded(!expanded);
  };

  const handleRemove = () => {
    onRemove && onRemove();
  };



  return (
    <div className={classes.root}>
      {
        onRemove && (
          <div className={classes.closeContainer}>
            <Tooltip title='Remove' aria-label='remove'>
              <div className={classes.close} onClick={() => handleRemove()}><IconButton><Icon>close</Icon></IconButton></div>
            </Tooltip>
          </div>
        )
      }
      <Box>
        <Typography variant='body2'>
          {tier.giveaway} Bike Giveaway - {tier.name}
        </Typography>
        <Typography variant='body2'>
          ${(tier.price/100)}/mo -- { tier.entries } Entries
        </Typography>
      </Box>
    </div>
  );
};

export default PlanItem;
