import {SubscriptionTier} from '../types/Subscription';

export const Tiers: SubscriptionTier[] = [
  {
    name: 'Tier 1',
    id: 'tier-1',
    giveaway: 'Beginner',
    price: 500,
    entries: 50,
    votes: 5,
    discord: true
  },
  {
    name: 'Tier 2',
    id: 'tier-2',
    giveaway: 'Beginner',
    price: 2000,
    entries: 200,
    votes: 20,
    discord: true
  },
  {
    name: 'Tier 3',
    id: 'tier-3',
    giveaway: 'Beginner',
    price: 5000,
    entries: 500,
    votes: 50,
    discord: true
  },
  {
    name: 'Tier 4',
    id: 'tier-4',
    giveaway: 'Beginner',
    price: 8000,
    entries: 800,
    votes: 80,
    discord: true
  },{
    name: 'Tier 1',
    id: 'intermediate-1',
    giveaway: 'Intermediate',
    price: 1000,
    entries: 100,
    votes: 10,
    discord: true
  },
  {
    name: 'Tier 2',
    id: 'intermediate-2',
    giveaway: 'Intermediate',
    price: 2500,
    entries: 250,
    votes: 25,
    discord: true
  },
  {
    name: 'Tier 3',
    id: 'intermediate-3',
    giveaway: 'Intermediate',
    price: 6000,
    entries: 600,
    votes: 60,
    discord: true
  },
  {
    name: 'Tier 4',
    id: 'intermediate-4',
    giveaway: 'Intermediate',
    price: 10000,
    entries: 1000,
    votes: 100,
    discord: true
  },
  {
    name: 'Tier 1',
    id: 'plan_H82BsohXYwiyvG',
    giveaway: 'Expert',
    price: 1000,
    entries: 100,
    votes: 10,
    discord: true
  },
  {
    name: 'Tier 2',
    id: 'plan_H82CA7NIkTr4wv',
    giveaway: 'Expert',
    price: 2500,
    entries: 250,
    votes: 25,
    discord: true
  },
  {
    name: 'Tier 3',
    id: 'plan_H82CMqMR7eXQIg',
    giveaway: 'Expert',
    price: 6000,
    entries: 600,
    votes: 60,
    discord: true
  },
  {
    name: 'Tier 4',
    id: 'plan_H82Cy9JhVkoP23',
    giveaway: 'Expert',
    price: 10000,
    entries: 1000,
    votes: 100,
    discord: true
  },
  {
    name: 'Tier 1',
    id: 'modern-1',
    giveaway: 'Modern Classic',
    price: 500,
    entries: 50,
    votes: 5,
    discord: true
  },
  {
    name: 'Tier 2',
    id: 'modern-2',
    giveaway: 'Modern Classic',
    price: 2000,
    entries: 200,
    votes: 20,
    discord: true
  },
  {
    name: 'Tier 3',
    id: 'modern-3',
    giveaway: 'Modern Classic',
    price: 5000,
    entries: 500,
    votes: 50,
    discord: true
  },
  {
    name: 'Tier 4',
    id: 'modern-4',
    giveaway: 'Modern Classic',
    price: 8000,
    entries: 800,
    votes: 80,
    discord: true
  }
];


