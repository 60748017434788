import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosServer from "../../../config/server";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { Promotion } from "../../../types/Promotion";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
        },
        toolbar: {
            borderBottome: '1px solid rgba(0,0,0,.15',
            '& a': {
                textDecoration: 'none'
            }
        },
        flex: {
            flexGrow: 1
        },
        tableContainer: {},
        icon: {
            fontSize: 24
        }
    })
);

const ManagePromotionsView: React.FC = () => {
    const classes = useStyles();
    const [promotions, setPromotions] = useState([]);
    useEffect(() => {
        ( async () => {
            try {
                const { data } = await AxiosServer.get('/admin/promotions');
                setPromotions(data.promotions);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const tableBody = (data: Promotion[]) => {
        return data.map((item: Promotion) => {
            let today:Date = new Date();
            let isPromotionEnded:Boolean = new Date(item.endDate) < today;
            return (
                <TableRow key={item.id}>
                    <TableCell>
                        <Typography variant='body1'>{item.giveawayType}</Typography>
                        <Typography variant='caption'>{isPromotionEnded ? 'Promotion Over' : new Date(item.endDate).toDateString()}</Typography>
                    </TableCell>
                    <TableCell>
                        {new Date(item.startDate).toDateString()}
                    </TableCell>
                    <TableCell>
                        {item.multiplier}
                    </TableCell>
                    <TableCell>
                        {item.hasMaxEntries ? 'TRUE' : 'FALSE'}
                    </TableCell>
                    <TableCell>
                        <Link to={`/admin/manage-promotions/p/${item.id}`}>
                            <StyledButton>Edit</StyledButton>
                        </Link>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <div className={classes.root}>
            <Grid container justify='center'>
                <Grid item xs={12} md={10}>
                    <div style={{textAlign: 'right'}}>
                        <Link to='/admin/manage-promotions/create'>
                            <StyledButton>
                                Create New Promotion
                            </StyledButton>
                        </Link>
                    </div>
                    <Paper className={classes.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>Mulitplier</TableCell>
                                    <TableCell>Is Max Entries?</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { promotions.length > 0 ? tableBody(promotions) : (
                                    <Box alignItems='center' padding={2}>
                                        <Typography variant='h2'>No Promotions</Typography>
                                    </Box>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>

        </div>
    )
}

export default ManagePromotionsView;