import React, { useEffect } from 'react';
import { useAuthStore } from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const LogoutView: React.FC = () => {
  const history = useHistory();
  const { logout } = useAuthStore();

  useEffect(() => {
    logout().then(() => history.push('/'));
  });

  return (
    <div/>
  );
};

export default LogoutView;
