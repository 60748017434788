import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const StyledDialog = withStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiDialog-paper': {
      backgroundColor: 'black'
    },
    '& .MuiDialogTitle-root': {

    },
    '& .MuiDialogContent-root': {
      color: 'white'
    }
  },
}))(Dialog);

export default StyledDialog;
