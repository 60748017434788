// React
import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import StyledButton from '../../components/StyledButton/StyledButton';
import AxiosServer from '../../config/server';
import StyledCard from '../../components/StyledCard/StyledCard';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import CardHeader from '@material-ui/core/CardHeader';
// Router Packages

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {

    },
    textField: {
      marginBottom: theme.spacing(2)
    }
  })
));

interface IState {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

interface FieldData {
  id: string;
  value: string;
  label: string;
  error: boolean;
  helperText: string;
}



const UpdatePasswordForm: React.FC = () => {
  const classes = useStyles();
  const [validated, setValidated] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseState, setResponseState] = useState<string>('');
  const [formState, setFormState] = useState<IState>({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setFormState({
      ...formState, [event.target.id]: event.target.value
    });
  };

  const handleValidation = () => {
    const errors: string[] = [];
    if (formState.currentPassword.length === 0) errors.push('Current password cannot be empty');
    if (formState.newPassword.length === 0) errors.push('New password cannot be empty');
    if (formState.newPassword.length === 0) errors.push('Confirm password password cannot be empty');
    setValidated(true);
    return errors;
  };

  const handleFormSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const errors = handleValidation();
    if (errors.length === 0) {
      try {
        const { data } = await AxiosServer.post('/users/update-password', formState);
        setResponseState(data.message);
      } catch (e) {
        setResponseState(e.response.data.message);
        console.log(e.response.data.message);
      }
    }
  };
  const fields: FieldData[] = [
    {
      id: 'currentPassword',
      value: formState.currentPassword,
      label: 'Current Password',
      error: validated && !formState.currentPassword,
      helperText: formState.currentPassword === '' && validated ? 'Cannot be empty' : ''
    },
    {
      id: 'newPassword',
      value: formState.newPassword,
      label: 'New Password',
      error: validated && !formState.newPassword,
      helperText: formState.newPassword === '' && validated ? 'Cannot be empty' : ''
    },
    {
      id: 'newPasswordConfirm',
      value: formState.newPasswordConfirm,
      label: 'Confirm New Password',
      error: validated && !formState.newPasswordConfirm,
      helperText: formState.newPasswordConfirm === '' && validated ? 'Cannot be empty' : ''
    },
  ];

  return (
    <div className={classes.root}>
      <StyledCard>
        <CardContent>
          <form>
            {
              fields.map((field: FieldData, key: number) => (
                <StyledTextField
                  key={key}
                  id={field.id}
                  value={field.value}
                  onChange={handleInputChange}
                  label={field.label}
                  variant={'outlined'}
                  className={classes.textField}
                  error={field.error}
                  helperText={field.helperText}
                  type={'password'}
                  fullWidth
                />
              ))
            }
          </form>
          {responseState && (<Box py={2}>{ responseState }</Box>)}
          <StyledButton variant={'contained'} onClick={handleFormSubmit}>Update Password</StyledButton>
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default UpdatePasswordForm;
