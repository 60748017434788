// React
import React, { useState } from 'react';
import AxiosServer from '../../../config/server';
import CardContent from '@material-ui/core/CardContent';
import {KeyboardDatePicker} from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import StyledButton from '../../../components/StyledButton/StyledButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardHeader from '@material-ui/core/CardHeader';
import StyledCard from '../../../components/StyledCard/StyledCard';
import NativeSelect from '@material-ui/core/NativeSelect';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';

interface IState {
  start: string;
  end: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePickerFields: {
      marginBottom: theme.spacing(2),
      '& label': {
        fontFamily: 'Teko'
      },
      '& input': {
        color: 'white'
      },
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
    progress: {
      margin: theme.spacing(1)
    }
  })
);


const ExportEntries: React.FC = () => {
  const classes = useStyles();
  const [formState, setFormState ] = useState<IState>({
    start: new Date().toString(),
    end: new Date().toString()
  });
  const [isLoading, setIsLoading] = useState(false);
  const [giveawayType, setGiveawayType] = useState('beginner');
  const [labelWidth] = useState(0);

  const handleDateChange = (field: string) => {
    return (date: Date | null) => {
      console.log(date);
      setFormState({ ...formState, [field]: date });
    };
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string;
    setGiveawayType(value);
  };


  const handleClick = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosServer.post('/admin/entries/export', {...formState, giveawayType }, {
        responseType: 'blob',
      });

      // Handle auto download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      document.body.appendChild(link);
      link.click();

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }

  };
  return (
    <div>
      <StyledCard>
        <CardHeader title={'Export Entries'}/>
        <CardContent>
          <FormControl fullWidth>
            <NativeSelect
              variant='outlined'
              className={classes.datePickerFields}
              value={giveawayType}
              onChange={handleTypeChange}
              input={<StyledSelect/>}
              inputProps={{
                name: 'type',
                id: 'type'
              }}
            >
              <option value={'beginner'}>Beginner</option>
              <option value={'intermediate'}>Intermediate</option>
              <option value={'expert'}>Expert</option>
              <option value={'modern'}>Modern</option>

            </NativeSelect>
          </FormControl>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Start Date"
            inputVariant="outlined"
            autoOk={true}
            value={formState.start}
            onChange={handleDateChange('start')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            fullWidth
            className={classes.datePickerFields}
          />

          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="End Date"
            inputVariant="outlined"
            autoOk={true}
            value={formState.end}
            onChange={handleDateChange('end')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoFocus={true}
            fullWidth
            className={classes.datePickerFields}
          />

          <Box display='flex' alignItems={'center'}>
            <StyledButton variant='contained' onClick={handleClick}>Export</StyledButton>
            {isLoading && <CircularProgress size={24} className={classes.progress} />}
          </Box>
        </CardContent>
      </StyledCard>
    </div>
  );

};

export default ExportEntries;
