import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Select} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

const StyledInputLabel = withStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: 'Teko',
    backgroundColor: 'black',
    padding: '0 3px',
    fontSize: 30
  },
}))(InputLabel);

export default StyledInputLabel;
