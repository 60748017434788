import React, {useEffect, useState} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AxiosServer from '../../../config/server';
import {User} from '../../../types/User';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

import StyledButton from '../../../components/StyledButton/StyledButton';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import Typography from '@material-ui/core/Typography';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import NativeSelect from '@material-ui/core/NativeSelect';
import {Entry} from '../../../types/Entry';
import {EntryHistoryTable} from '../../../components/EntryHistoryTable/EntryHistoryTable';


interface IProps {
  visible: boolean;
  onClose: Function;
  user?: User;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    dialog: {
      minWidth: '60%'
    },
    formControl: {
      marginBottom: theme.spacing(2)
    },


  })
);

const UserEntryModal: React.FC<IProps> = ({ visible, onClose, user }) => {
  const classes = useStyles();
  const [response, setResponse] = useState<string>('');
  const [entries, setEntries] = useState<number>(0);
  const [entryHistory, setEntryHistory] = useState<Entry[]>([]);
  const [labelWidth] = useState(0);
  const [giveawayType, setGiveawayType] = useState('beginner');

  useEffect(() => {
    if (user) {
      (async () => {
        await fetchEntries();
      })();
    }

  }, [visible]);

  const fetchEntries = async () => {
    try {
      const { data } = await AxiosServer.get(`/admin/users/${user.id}/entries-history`);
      console.log(data);
      setEntryHistory(data);
    } catch (e) {
      console.log(e);
    }
  };

  const addPoints = async () => {
    if (user) {
      try {
        const { data } = await AxiosServer.post(`/admin/users/${user.id}/add-entries`, {
          entries,
          giveawayType
        });
        setResponse(data.message);
        setEntries(0);
        await fetchEntries();
      } catch (e) {
        setResponse(e.response.data.message);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntries(parseInt(event.target.value));
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string;
    setGiveawayType(value);
  };

  return (
    <div className={classes.root}>
      <StyledDialog open={visible} className={classes.dialog} onClose={() => onClose()} scroll={'paper'}>
        <DialogTitle>Entries Management</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth>
              <NativeSelect
                value={giveawayType}
                onChange={handleTypeChange}
                inputProps={{
                  name: 'type',
                  id: 'type'
                }}
                input={<StyledSelect/>}
              >
                <option value={'beginner'}>Beginner</option>
                <option value={'intermediate'}>Intermediate</option>
                <option value={'expert'}>Expert</option>
                <option value={'modern'}>Modern Classic</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth>
              <StyledTextField
                variant='outlined'
                type='number'
                onChange={handleInputChange}
                value={entries}
              />
            </FormControl>
          </form>
          <EntryHistoryTable entries={entryHistory}/>
        </DialogContent>
        <Box m={2} textAlign={'center'}>
          <Typography variant='body2'>{response}</Typography>
        </Box>
        <DialogActions>
          <StyledButton onClick={addPoints}>Add Points</StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default UserEntryModal;
