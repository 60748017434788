
export type EntryDataSchema = {
  monthEntries: number;
  totalEntries: number;
  type: string;
};

export interface EntryState {
  entries: EntryDataSchema[] | unknown;
  isLoading?: boolean;
};

export type EntryActions = {
  type: 'setEntries' | 'reset' | 'setLoading';
  payload?: EntryState | boolean;
};

export const InitialEntryState: EntryState = {
  entries: [],
  isLoading: false
};

export const Reducer = (state: EntryState, action: EntryActions): EntryState => {
  switch (action.type) {
  case 'setEntries': {
    return { ...state, entries: action.payload as unknown, isLoading: false };
  }
  case 'setLoading': {
    return { ...state, isLoading: action.payload as boolean };
  }
  default:
    return state;
  }
};


