// React
import React, { useState } from 'react';
import AxiosServer from '../../../config/server';
import CardContent from '@material-ui/core/CardContent';
import {KeyboardDatePicker} from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import StyledCard from '../../../components/StyledCard/StyledCard';
import StyledButton from '../../../components/StyledButton/StyledButton';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import NativeSelect from '@material-ui/core/NativeSelect';
import ValidationModal from '../../../components/ValidationModal/ValidationModal';

interface IState {
  date: string;
  isModalVisible: boolean;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePickerFields: {
      marginBottom: theme.spacing(2),
      '& label': {
        fontFamily: 'Teko'
      },
      '& input': {
        color: 'white'
      },
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
  })
);


const CashOutEntries: React.FC = () => {
  const classes = useStyles();
  const [formState, setFormState ] = useState<IState>({
    date: new Date().toString(),
    isModalVisible: false
  });
  const [giveawayType, setGiveawayType] = useState('beginner');
  const [labelWidth] = useState(0);
  const [response, setResponse] = useState('');

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string;
    setGiveawayType(value);
  };

  const handleDateChange = (field: string) => {
    return (date: Date | null) => {
      console.log(date);
      setFormState({ ...formState, [field]: date });
    };
  };

  const handleFormSubmit = async () => {
    try {
      console.log(new Date('2019-10-1').toString());
      const { data } = await AxiosServer.post('/admin/entries/cashout', {...formState, giveawayType });
      setResponse(data.message);
      setFormState({ ...formState, isModalVisible: false });
    } catch (e) {
      console.log(e); 
      setFormState({ ...formState, isModalVisible: false });
    }
  };

  return (
    <div>
      <ValidationModal 
        dialogTitle='Are You Sure?'
        dialogContent='This action will modify values for the entries database'
        isVisible={formState.isModalVisible}
        onConfirm={() => handleFormSubmit()}
        onCancel={() => setFormState({ ...formState, isModalVisible: false })}
      />
      <StyledCard>
        <CardHeader title='Cash Out Entries'/>
        <CardContent>
          <FormControl fullWidth>
            <NativeSelect
              variant='outlined'
              className={classes.datePickerFields}
              value={giveawayType}
              onChange={handleTypeChange}
              inputProps={{
                name: 'type',
                id: 'type'
              }}
              input={<StyledSelect/>}
            >
              <option value={'beginner'}>Beginner</option>
              <option value={'intermediate'}>Intermediate</option>
              <option value={'expert'}>Expert</option>
              <option value={'modern'}>Modern</option>

            </NativeSelect>
          </FormControl>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Giveaway End Date"
            inputVariant="outlined"
            autoOk={true}
            value={formState.date}
            onChange={handleDateChange('date')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            fullWidth
            className={classes.datePickerFields}
          />
          <Box fontStyle={'italic'} mb={2}>All entries up to the selected date will be cashed out</Box>
          <StyledButton variant='contained' color='primary' onClick={() => setFormState({...formState, isModalVisible: true})}>
            Cash Out
          </StyledButton>
          <Box my={2}>
            { response }
          </Box>
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default CashOutEntries;
