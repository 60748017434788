import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import UserTable from './UserTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    }
  })
);


const ManageUsersView: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UserTable/>
    </div>
  );
};

export default ManageUsersView;
