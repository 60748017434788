import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';

interface IProps {
  formErrors: string[];
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      marginBottom: theme.spacing(2),
      border: `1px solid ${red[300]}`,
      borderRadius: 5,
      padding: theme.spacing(2),
      backgroundColor: red[400],
      color: 'white'
    },
    list: {
      fontWeight: 600,
      margin: '0',
      padding: `${theme.spacing(1)}px 0`,
      listStyle: 'none'
    }
  }
  ));

const FormErrorResponse: React.FC<IProps> = ({ formErrors }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box fontWeight={800} fontSize={18}>
        Error
      </Box>
      <ul className={classes.list}>
        { formErrors.map( (error: string, key: number) => <li key={key}>{error}</li>) }
      </ul>
    </div>
  );
};

export default FormErrorResponse;
