// React
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import UpdatePasswordForm from './UpdatePasswordForm';
import SubscriptionStatusCard from '../Subscription/SubscriptionStatusCard';
import Box from '@material-ui/core/Box';
import DiscordCard from '../../components/Discord/DiscordCard';
import EditProfile from '../../components/EditProfile/EditProfile';
import { useAuthStore } from '../../hooks/useAuth';
import PlansCard from '../../components/PlansCard/PlansCard';
import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      padding: theme.spacing(2),
      maxWidth: 600,
      margin: 'auto'
    }
  })
));

const AccountManageView: React.FC = () => {
  const classes = useStyles();
  const { user, isAdmin } = useAuthStore();

  if (!isAdmin) {
    return (
      <></>
    );
  } else {
    return (
      <div className={classes.root}>
        <Box mb={2}>
          <Typography variant='h3'>Subscription</Typography>
          <SubscriptionStatusCard />
        </Box>
        {
          user.stripeProfile?.active &&
          <>
            <Box mb={2}>
              <Typography variant='h3'>Plans</Typography>
              <PlansCard />
            </Box>
            <Box mb={2}>
              <Typography variant='h3'>Discord</Typography>
              <DiscordCard />
            </Box>
          </>
        }
        <Box mb={2}>
          <Typography variant='h3'>Edit Profile</Typography>
          <EditProfile />
        </Box>
        <Box mb={2}>
          <Typography variant='h3'>Update Password</Typography>
          <UpdatePasswordForm />
        </Box>
        <Box mb={2}>
          <DeleteAccount />
        </Box>`
      </div>
    );
  }

};

export default AccountManageView;
