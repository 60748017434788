import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: 'Teko',
    backgroundColor: 'rgba(0,0,0,1)',
    fontSize: '25px',
    boxShadow: 'none',
    borderRadius: 0,
    border: '4px solid #EBB232',
    color: '#EBB232',
    padding: '10px 25px 8px',
    '&:hover': {
      backgroundColor: 'white',
    },
    marginBottom: theme.spacing(2)
  },
}))(Button);

export default StyledButton;
