import React from 'react';
import { Link } from 'react-router-dom';
import { Theme, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MenuItem} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface LinkItem {
  label: string;
  path: string;
  icon: JSX.Element;
}


interface IProps {
  links: LinkItem[];
}

const AdminDropdown: React.FC<IProps> = ({links}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <Button color={'secondary'} variant={'contained'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Admin
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'right'
        }}
      >
        {
          links.map(({ path, label }: LinkItem) => (
            <Link key={label} to={path}><MenuItem onClick={handleClose}>{label}</MenuItem></Link>
          ))
        }
      </Menu>
    </div>
  );
};

export default AdminDropdown;
