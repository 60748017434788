import React, {createContext, useReducer, useEffect} from 'react';
import { Reducer, InitialAuthState, AuthState } from './reducer';
import server from '../../config/server';
import {User} from '../../types/User';

interface ContextProps extends AuthState {
  login: (data: Partial<User>) => Promise<string | undefined>;
  register: (data: Partial<User>) => Promise<string | undefined>;
  setUser: (data: User) => Promise<void>;
  fetchUser: () => Promise<void>;
  logout: () => Promise<void>;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const AuthStore = createContext({} as ContextProps);

export const AuthStoreProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialAuthState);

  useEffect(() => {
    if (!state.isUserLoggedIn && window.sessionStorage.getItem('auth-token')) {
      (async () => {
        fetchUser();
      })();
    }
  }, [state.isUserLoggedIn]);

  async function fetchUser() {
    const { data } = await server.get('/auth/token');
    dispatch({type: 'login', payload: data.user});
  }

  async function logout() {
    dispatch({type: 'logout'});
    window.sessionStorage.removeItem('auth-token');
  }

  async function setUser(user: User): Promise<void> {
    dispatch({ type: 'setUser', payload: { user, isUserLoggedIn: true, isAdmin: user.isAdmin}});
  }

  async function register(body: Partial<User>): Promise<string | undefined> {
    try {
      const response = await server.post('/auth/register', {
        ...body,
        email: body.email.trim().toLowerCase(),
        phone: body.phone.replace(/\D+/g, '')
      });
      const { data } = response;
      if (data && data.user) {
        window.sessionStorage.setItem('auth-token', data.token);
        dispatch({ type: 'login', payload: data.user });
      }
    } catch (e) {
      console.log(e);
      await logout();
      return e.response.data.message;
    }
  }

  async function login (body: Partial<User>): Promise<string | undefined> {
    try {
      const { data } = await server.post('/auth/login', body);
      if (data && data.user) {
        window.sessionStorage.setItem('auth-token', data.token);
        dispatch({ type: 'login', payload: data.user });
      }
    } catch (e) {
      await logout();
      return e.response.data.message;
    }
  }

  return (
    <AuthStore.Provider value={{ ...state, login, logout, register, setUser, fetchUser }}>
      { children }
    </AuthStore.Provider>
  );
};

