import { User } from '../../types/User';
import {Giveaway} from '../../types/Giveaway';

export interface GiveawayState {
  giveaways: Giveaway[];
}

export type AuthActions = {
  type: 'setGiveaways';
  payload?: Giveaway[];
};

// The user is defined here for autocompletion help downstream.
export const InitialGiveawayState: GiveawayState = {
  giveaways: []
};

export const Reducer = (state: GiveawayState, action: AuthActions): GiveawayState => {
  switch (action.type) {
  case 'setGiveaways': {
    return { ...state, giveaways: (action.payload) || [] };
  }
  default: {
    return state;
  }
  }
};


