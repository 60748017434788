import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import {useAuthStore} from '../../hooks/useAuth';
import Box from '@material-ui/core/Box';
import {SubscriptionTier} from '../../types/Subscription';
import PlanItem from '../PlanItem/PlanItem';
import { Tiers } from '../../data/Tiers';
import StyledCard from '../StyledCard/StyledCard';
import Typography from '@material-ui/core/Typography';


const arraysAreEqual = (_arr1: any[], _arr2: any[]) => {
  if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
    return false;
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i])
      return false;
  }
  return true;
};

const PlansCard: React.FC = () => {
  const { user } = useAuthStore();
  const stripeProfile = user.stripeProfile;
  const currentPlans = user.stripeProfile?.plans;
  const upcomingPlans = user.stripeProfile?.upcomingPlans;
  console.info(currentPlans);
  console.info(upcomingPlans);
  let hasNewPlans = false;
  if (currentPlans && upcomingPlans) {
    hasNewPlans = !arraysAreEqual(currentPlans, upcomingPlans);
  }
  const tierPlans = user.stripeProfile?.plans?.map((planId: string) =>
    Tiers.find((x: SubscriptionTier) => x.id === planId)
  );
  const tiersUpcoming = user.stripeProfile?.upcomingPlans?.map((planId: string) =>
    Tiers.find((x: SubscriptionTier) => x.id === planId)
  );

  return (
    <StyledCard>
      <CardContent>
        <Box>
          <Typography variant='h6'>
            Current Plans
          </Typography>
          {
            tierPlans?.map((tier: SubscriptionTier | undefined) => {
              return tier && <PlanItem tier={tier} currentPlan={currentPlans && currentPlans.indexOf(tier.id) > -1}/>;
            })
          }
        </Box>
        <Box marginTop={2}>
          {
            (hasNewPlans && tiersUpcoming?.length !== 0) && (
              <>
                <Typography variant='h6'>
                  Upcoming Plans - Starting { stripeProfile?.subscriptionPeriodEnd && new Date(stripeProfile?.subscriptionPeriodEnd).toDateString() }
                </Typography>
                {
                  tiersUpcoming?.map((tier: SubscriptionTier | undefined) => {
                    return tier && <PlanItem tier={tier} currentPlan={currentPlans && currentPlans.indexOf(tier.id) > -1}/>;
                  })
                }
              </>
            )
          }
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default PlansCard;
