import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import StyledButton from '../StyledButton/StyledButton';
import StyledDialog from '../StyledDialog/StyledDialog';
import {Typography} from '@material-ui/core';

interface IProps {
    dialogTitle: string;
    dialogContent: string;
    isVisible: boolean;
    onCancel: Function;
    onConfirm: Function;
}

const ValidationModal: React.FC<IProps> = ({ dialogTitle, isVisible, dialogContent, onCancel, onConfirm }) => {
    return (
        <StyledDialog open={isVisible}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Typography variant='body2'>
                    {dialogContent}
                </Typography>
            </DialogContent>
            <DialogActions>
                <StyledButton variant={'contained'} onClick={() => { onCancel() }}>Cancel</StyledButton>
                <StyledButton variant={'contained'} onClick={() => { onConfirm() }}>Okay</StyledButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default ValidationModal;