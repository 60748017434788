import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Giveaway, GiveawayItem } from '../../../types/Giveaway';
import GiveawayItemForm from './GiveawayItemForm';
import { AxiosResponse } from 'axios';
import GiveawayItemToolbar from '../../../components/GiveawayItemToolbar/GiveawayItemToolbar';
import server from '../../../config/server';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FileUploader from '../../../components/FileUploader/FileUploader';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../components/StyledButton/StyledButton';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)'
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {
      marginTop: theme.spacing(4)
    },
    form: {
      marginTop: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    },
    actionContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    },
    itemToolbar: {
      borderTop: '1px solid rgba(0,0,0,.15)',
    },
    button: {
      marginRight: theme.spacing(2)
    },
    datePickerFields: {
      marginRight: theme.spacing(2),
      '& label': {
        fontFamily: 'Roboto'
      },
      '& input': {
        color: 'white'
      },
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '& .MuiButtonBase-root': {
        color: 'white'
      }
    },
    responseContainer: {
      marginTop: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2)
    }
  })
);

interface IState {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  officialRulesUrl?: string;
  type: string;
};

type TParams = {
  giveawayId: string;
};

interface IProps {
  giveaway: Giveaway;
};

interface ParamsState {
  giveawayId: string;
}

const EditGiveawayView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { giveawayId } = useParams<ParamsState>();
  const [labelWidth] = React.useState(0);
  const [formState, setFormState] = useState<IState>({
    title: '',
    description: '',
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    officialRulesUrl: '',
    type: ''
  });

  const [itemsState, setItems] = useState<GiveawayItem[]>([]);

  const [responseState, setResponseState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  // Set the Form
  useEffect(() => {
    server.get(`/admin/giveaways/${giveawayId}`)
      .then((response: AxiosResponse) => {
        const giveaway = response.data.giveaway as Giveaway;
        console.log(giveaway);
        setFormState({
          ...formState,
          title: giveaway.title,
          description: giveaway.description,
          startDate: new Date(giveaway.startDate).toISOString(),
          endDate: new Date(giveaway.endDate).toString(),
          officialRulesUrl: giveaway.officialRulesUrl,
          type: giveaway.type
        });
        setItems(giveaway.items);
      }).catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const value = event.target.value as string;
    setFormState({...formState, type: value});
  };

  const handleOfficialRulesUpload = (url: string) => {
    setFormState({ ...formState, 'officialRulesUrl': url });
  };

  const handleDateChange = (field: string) => {
    return (date: Date | null) => {
      if (date)
        date.setHours(0,0,0,0);
      setFormState({ ...formState, [field]: date });
    };
  };

  // Toggle add new item form
  const handleShowItemForm = () => {
    setIsAdding(!isAdding);
  };

  // Giveaway Item management
  const handleAddedItem = (giveawayItem: GiveawayItem) => {
    setItems([...itemsState, giveawayItem]);
    setIsAdding(!isAdding);
  };

  const handleRemoveItem = (id: string) => {
    setItems(itemsState.filter(x => x.id !== id));
  };

  // When giveaway item gets updated, this updates the view
  const handleUpdateItem = (item: GiveawayItem) => {
    const index = itemsState.findIndex(x => x.id === item.id);
    const items = [...itemsState];
    items[index] = item;
    setItems(items);
  };

  // Save giveaway details
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { title, description, startDate, endDate, officialRulesUrl, type } = formState;
      await server.patch(`/admin/giveaways/${giveawayId}`, { title, description, startDate, endDate, officialRulesUrl, type });
      setIsLoading(false);
      history.push('/admin/manage-giveaways');
    } catch (error) {
      setIsLoading(false);
      setResponseState(error.response.data.message);
    }
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} variant='dense' >
        <Typography variant={'body2'}>Editing: {formState.title}</Typography>
        <div className={classes.flex} />
        <StyledButton className={classes.button} variant='outlined' size='small' onClick={handleSubmit}>Save</StyledButton>
      </Toolbar>
      <Grid container justify='center'>
        <Grid item xs={12} md={8}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl className={classes.formControl} fullWidth>
              <NativeSelect
                variant='outlined'
                value={formState.type}
                onChange={handleTypeChange}
                inputProps={{
                  name: 'type',
                  id: 'type'
                }}
                input={<StyledSelect/>}
              >
                <option value={''}></option>
                <option value={'beginner'}>Beginner</option>
                <option value={'intermediate'}>Intermediate</option>
                <option value={'expert'}>Expert</option>
                <option value={'modern'}>Modern Classic</option>

              </NativeSelect>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <StyledTextField
                name='title'
                label='Title'
                fullWidth
                className={classes.textField}
                onChange={handleChange}
                value={formState.title}
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <StyledTextField
                name='description'
                label='Description'
                multiline
                rows={4}
                fullWidth
                className={classes.textField}
                onChange={handleChange}
                value={formState.description}
                variant='outlined'
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                label="Start Date"
                inputVariant="outlined"
                autoOk={true}
                value={formState.startDate}
                onChange={handleDateChange('startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoFocus={true}
                className={classes.datePickerFields}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                label="End Date"
                inputVariant="outlined"
                autoOk={true}
                value={formState.endDate}
                onChange={handleDateChange('endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoFocus={true}
                className={classes.datePickerFields}
              />
            </FormControl>
          </form>
          <Typography variant={'h4'}>
            Upload Official Rules
          </Typography>
          <Box>
            <FileUploader onUploadComplete={handleOfficialRulesUpload} />
            { formState.officialRulesUrl ?
              <StyledButton><a target='_blank' href={formState.officialRulesUrl} rel='noreferrer'>View Rules</a></StyledButton> :
              <Typography display='inline' variant='body2'>Rules Attached</Typography>
            }
          </Box>
          <div className={classes.actionContainer}>
            <StyledButton variant='outlined' size='large' onClick={handleSubmit}>Save</StyledButton>
            {isLoading && <CircularProgress size={24} className={classes.progress} />}
          </div>
          <div className={classes.responseContainer}>{responseState}</div>
          <Typography variant={'h4'}>
            Giveaway Items
          </Typography>
          <Toolbar className={classes.itemToolbar}>
            <div className={classes.flex} />
            <StyledButton onClick={handleShowItemForm}>
              {isAdding ? 'Cancel' : 'New Giveaway Item'}
            </StyledButton>
          </Toolbar>
          {isAdding && <GiveawayItemForm onComplete={handleAddedItem} giveawayId={giveawayId} editing={false} />}
          {itemsState.map((item: GiveawayItem, key: number) => {
            return (
              <GiveawayItemToolbar key={key} giveawayItem={item} onRemove={handleRemoveItem} onUpdate={handleUpdateItem} />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default EditGiveawayView;
