import React, { useState } from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GiveawayItem } from '../../types/Giveaway';
import GiveawayItemForm from '../../views/Admin/ManageGiveaways/GiveawayItemForm';
import server from '../../config/server';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../StyledButton/StyledButton';
import StyledCard from '../StyledCard/StyledCard';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2)
    },
    flex: {
      flexGrow: 1
    },
    toolbar: {
      borderTop: '1px solid rgba(0,0,0,.15)',
      display: 'flex',
      '& img': {
        height: 64,
      }
    },
    imgContainer: {
      minWidth: 64,
      margin: theme.spacing(2)
    },
    name: {
      minWidth: '25%'
    },
    description: {
      minWidth: '50%',
      textOverflow: 'truncate'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    cardMedia: {
      height: 250
    }
  })
);

interface IState {
  id: string;
  name: string;
  description: string;
  value: number;
  photoUrl: string;
}

interface IProps {
  giveawayItem: GiveawayItem;
  onRemove: Function;
  onUpdate: Function;
};



const GiveawayItemToolbar: React.FC<IProps> = ({giveawayItem, onRemove, onUpdate}) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);

  const handleRemoveItem = async () => {
    try {
      await server.delete(`/giveawayitems/${giveawayItem.id}`);
      onRemove(giveawayItem.id);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleForm = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdateComplete = (giveawayItem: GiveawayItem) => {
    onUpdate(giveawayItem);
    toggleForm();
  };

  return (
    <div className={classes.root}>
      <StyledCard>
        <CardHeader title={giveawayItem.name}/>
        <CardMedia
          className={classes.cardMedia}
          image={giveawayItem.photoUrl}
        />
        <CardContent>
          { giveawayItem.description }
        </CardContent>
        <CardActions>
          <StyledButton className={classes.button} onClick={toggleForm}>Edit</StyledButton>
          <StyledButton className={classes.button} onClick={handleRemoveItem}>Delete</StyledButton>
        </CardActions>
      </StyledCard>
      {/*<Toolbar className={classes.toolbar}>*/}
      {/*  <div className={classes.imgContainer}>*/}
      {/*    <img alt={giveawayItem.photoUrl} src={giveawayItem.photoUrl} />*/}
      {/*  </div>*/}

      {/*  <Typography variant={'h5'} className={classes.name}>{giveawayItem.name}</Typography>*/}
      {/*  <Typography variant={'h5'} className={classes.description}>{giveawayItem.description}</Typography>*/}

      {/*</Toolbar>*/}
      {
        isEditing &&
        <GiveawayItemForm giveawayId={giveawayItem.giveaway} giveawayItem={giveawayItem} onComplete={handleUpdateComplete} editing={true} />
      }

    </div>
  );
};

export default GiveawayItemToolbar;
