import React, { useState } from 'react';
import AxiosServer from '../../../config/server';
import { User } from '../../../types/User';
import MaterialTable from 'material-table';
import UserEntryModal from './UserEntryModal';
import IconButton from '@material-ui/core/IconButton';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import ProfileModel from './ProfileModel';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiIconButton-label': {
        color: 'white'
      },
      '& .MTablePaginationInner-root-71': {
        flexDirection: 'row-reverse'
      }
    }
  })
);

const UserTable: React.FC = () => {
  const classes = useStyles();
  const [showingModal, setShowingModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [showingProfileModal, setShowingProfileModal] = useState<boolean>(false);


  const handleShowProfileModal = (user: User) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedUser(user);
      setShowingProfileModal(true);
    };
  };

  const handleHideProfileModal = () => {
    setSelectedUser(undefined);
    setShowingProfileModal(false);
  };

  const handleShowEntriesModal = (user: User) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedUser(user);
      setShowingModal(true);
    };
  };

  const handleHideEntriesModal = () => {
    setSelectedUser(undefined);
    setShowingModal(false);
  };

  const columns = [
    { title: 'First Name', field: 'firstName'},
    { title: 'Last Name', field: 'lastName' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    {
      title: 'Plan',
      field: 'subscriptionPlan',
      render: (user: User) => user.stripeProfile?.active ? `Tier ${user.stripeProfile.highestTier}` : 'Free'
    },
    {
      title: 'Join date',
      field: 'createdAt',
      render: (user: User) => new Date(user.createdAt).toDateString()
    },
    {
      title: 'More',
      render: (user: User) => (<>
        <IconButton onClick={handleShowProfileModal(user)}><PersonIcon/></IconButton>
        <IconButton onClick={handleShowEntriesModal(user)}><ConfirmationNumberIcon/></IconButton>
      </>)
    }
  ];

  return (
    <div className={classes.root}>
      <UserEntryModal visible={showingModal} onClose={handleHideEntriesModal} user={selectedUser}/>
      <ProfileModel visible={showingProfileModal} onClose={handleHideProfileModal} user={selectedUser}/>
      <MaterialTable
        columns={columns}
        data={query =>
          new Promise(async (resolve, reject) => {
            try {
              const { data } = await AxiosServer.get(`/admin/users?pageSize=${query.pageSize}&page=${ query.page }&search=${query.search}`);
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.totalCount
              });
            } catch (e) {
              reject(e);
            }
          })
        }
        title='Users'
        options={{
          searchFieldStyle: {
            color: 'white'
          },
          headerStyle: {
            backgroundColor: 'black',
            color: 'white',
            fontFamily: 'Teko'
          }
        }}
        style={{
          backgroundColor: 'black',
          color: 'white',
        }}
      />
    </div>
  );
};

export default UserTable;
