import axios from 'axios';


const AxiosServer = axios.create({
  // baseURL: 'https://yammie-api.herokuapp.com/api/v1'
  baseURL: process.env.REACT_APP_API_URL
});

/**
 * Intercept every request and add authorization header
 * because the token may not be available on load but then it becomes
 * available after login.
 **/

AxiosServer.interceptors.request.use(function (config) {
  if (window.sessionStorage.getItem('auth-token'))
    config.headers.Authorization =  window.sessionStorage.getItem('auth-token');
  return config;
});


export default AxiosServer;
