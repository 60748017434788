import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import AxiosServer from '../../config/server';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../StyledButton/StyledButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    list: {
      listStyle: 'none',
      margin: '2rem 0',
      padding: 0,

      '& li' : {
        margin: 0,
        padding: 0
      }
    }
  })
);

const DeleteAccount: React.FC = () => {
  const classes = useStyles();
  const [readyToDelete, setReadyToDelete] = useState(false);
  const history = useHistory();

  const toggleDelete = () => {
    setReadyToDelete(true);
  };

  const handleSubmit = async () => {
    try {
      await AxiosServer.delete('/users');
      history.push('/logout');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.root}>

      { readyToDelete && (
        <>
          <Typography variant='body2'>I understand that my entries will be deleted, I will not be eligible to win any bikes, and I will no longer be able to access my account.<br/>THIS ACTION CANNOT BE REVERSED.</Typography>
        </>
      )}
      {!readyToDelete ? <StyledButton onClick={toggleDelete}>I want to delete my account</StyledButton> : <StyledButton onClick={handleSubmit}>Yes delete my Account</StyledButton>}
    </div>
  );
};

export default DeleteAccount;
