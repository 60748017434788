import React, { useState, useEffect } from 'react';


import Grid from '@material-ui/core/Grid';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import { GiveawayItem } from '../../../types/Giveaway';
import FileUploader from '../../../components/FileUploader/FileUploader';
import server from '../../../config/server';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../../components/StyledButton/StyledButton';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'black',
      borderBottom: '1px solid rgba(0,0,0,.15)',
      borderTop: '1px solid rgba(0,0,0,.15)',
      padding: `${theme.spacing(2)}px 0px`
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)'
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {
      marginTop: theme.spacing(4)
    },
    form: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center'
    },
    uploader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: 128,
        marginLeft: theme.spacing(4)
      }
    }
  })
);

interface IProps {
  giveawayItem?: GiveawayItem;
  giveawayId: string;
  onComplete: Function;
  editing: boolean;
}

interface IState {
  name: string;
  description: string;
  value: number;
  photoUrl: string;
}


const GiveawayItemForm: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const [formState, setFormState] = useState<IState>({
    name: '',
    description: '',
    value: 0,
    photoUrl: ''
  });

  const [responseState, setResponseState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Set the Form
  useEffect(() => {
    if (props.giveawayItem) {
      const { name, description, value, photoUrl } = props.giveawayItem;
      setFormState({
        name, description, value, photoUrl
      });
    }
  }, [props.giveawayItem]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSaveItem = async () => {

    const response = await server.post('giveawayitems', {...formState, giveawayId: props.giveawayId});
    setIsLoading(false);
    // Done saving
    props.onComplete(response.data.giveawayItem);
  };

  const handleUpdateItem = async () => {
    setIsLoading(true);
    try {
      const response = await server.patch(`giveawayitems/${props.giveawayItem!.id}`, formState);
      props.onComplete(response.data.giveawayItem);
    } catch (error) {
      setIsLoading(false);
      setResponseState(error.response.data.message);
    }
  };

  const handlePhotoUpload = (url: string) => {
    setFormState({
      ...formState,
      photoUrl: url
    });
  };

  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} md={8}>
          <form className={classes.form} onSubmit={handleSaveItem}>
            <StyledTextField
              name='name'
              label='Name'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.name}
              variant='outlined'
            />
            <StyledTextField
              name='description'
              label='Description'
              multiline
              rows={4}
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={formState.description}
              variant='outlined'
            />
            <StyledTextField
              className={classes.textField}
              variant='outlined'
              label='Value'
              name='value'
              value={formState.value}
              onChange={handleChange}
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <Typography variant='h4'>
              Upload Photo
            </Typography>
            <div className={classes.uploader}>
              <FileUploader onUploadComplete={handlePhotoUpload} />
              {formState.photoUrl !== '' && <img alt={formState.photoUrl} src={formState.photoUrl} />}
            </div>
          </form>
        </Grid>
        <Grid item xs={11}>
          <div className={classes.actionContainer}>
            {
              props.editing ?
                <StyledButton size='large' variant='outlined' onClick={handleUpdateItem}>Update Item</StyledButton>
                : <StyledButton size='large' variant='outlined' onClick={handleSaveItem}>Add Item</StyledButton>
            }
            {isLoading && <CircularProgress size={24} className={classes.progress} />}
          </div>
          <div>{responseState}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GiveawayItemForm;
