import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';

const StyledCard = withStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: 'black',
    boxShadow: 'none',
    '& .MuiCardHeader-root': {
      color: 'white',
      fontFamily: 'Teko'
    },
    '& .MuiCardContent-root': {
      color: 'white',
      fontFamily: 'Roboto',
      fontSize: 18,
    }
  },
}))(Card);

export default StyledCard;
