import React from 'react';
import {Entry} from '../../types/Entry';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const EntryHistoryTable: React.FC<{entries: Entry[]}> = ({ entries }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Entries</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Giveaway</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          entries.map((entry: Entry, key: number) => {
            return (
              <TableRow key={key}>
                <TableCell>{new Date(entry.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{entry.entries}</TableCell>
                <TableCell>{entry.type}</TableCell>
                <TableCell>{entry.giveawayType}</TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  )
};
