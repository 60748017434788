import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAuthStore } from '../../hooks/useAuth';
import useQuery from '../../hooks/useQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FormErrorResponse from '../../components/FormError/FormErrorResponse';
import StyledButton from '../../components/StyledButton/StyledButton';
import Box from '@material-ui/core/Box';
import StyledTextField from '../../components/StyledTextField/StyledTextField';
import StyledCard from '../../components/StyledCard/StyledCard';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      paddingTop: theme.spacing(8),
      marginBottom: theme.spacing(10),
      maxWidth: 500,
      minHeight: 'calc(100vh - 64px)',
      margin: 'auto',
      borderRadius: 5
    },
    title: {
      marginBottom: theme.spacing(4)
    },
    form: {
      padding: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2),
    },
    progress: {
      margin: theme.spacing(2)
    },
  })
);

interface State {
  email: string;
  password: string;
}



const LoginView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { login, isUserLoggedIn, isAdmin } = useAuthStore();
  const classes = useStyles();
  const [state, setState] = useState<State>({
    email: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  // console.log(query);

  useEffect(() => {
    if (!isUserLoggedIn) {
      // window.location.replace('https://www.yammienoob.co/');
    } else {

      let redirectTo = '/account';

      if (!isAdmin) {
        window.location.replace('https://www.yammienoob.co/');
      } else {
        // if (query.get('redirect') === 'store') {
        //   redirectTo = '/store';
        // }
        if (location.state) {
          // @ts-ignore
          redirectTo = location.state?.from.pathname;
        }
        history.push(redirectTo);
      }

    }
  }, [history, isAdmin, isUserLoggedIn, location.state, login, query]);

  const validateLogin = () => {
    const errors: string[] = [];
    if (state.email.length === 0) {
      errors.push('Email cannot be empty');
    }
    if (state.password.length === 0) {
      errors.push('Password cannot be empty');
    }
    return errors;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = await validateLogin();
    if (errors.length !== 0) {
      setFormErrors(errors);
    } else {
      setIsLoading(true);
      try {
        const error = await login(state);
        setIsLoading(false);
        if (error) {
          setFormErrors([error]);
          return;
        }
        if (query.get('redirect') === 'store') {
          history.push('/store');
        } else {
          history.push('/account');
        }

      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className={classes.root}>
      <StyledCard>
        <CardContent>
          <Typography variant='h3'>Login</Typography>
          {
            query.get('redirect') === 'store' && (
              <Typography variant='body2'>You will be redirected to the store after you login</Typography>
            )
          }
          <form className={classes.form} onSubmit={handleLogin}>
            {formErrors.length > 0 && <FormErrorResponse formErrors={formErrors} />}
            <StyledTextField
              name='email'
              label='Email'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.email}
              variant='outlined'
            />
            <StyledTextField
              name='password'
              label='Password'
              type='password'
              fullWidth
              className={classes.textField}
              onChange={handleChange}
              value={state.password}
              variant='outlined'
            />
            {isLoading && <CircularProgress size={24} className={classes.progress} />}<br />
            <Box my={4}>
              <StyledButton type='submit' onClick={handleLogin} variant='contained' size='large'>Login</StyledButton>
            </Box>
            {/* <Box color={'white'}>
              <Link to={`/register?redirect=${query.get('redirect')}`} className='link-no-style' style={{ textDecoration: 'underline', color: 'white'}}>Register</Link> or <Link to='/forgot' className='link-no-style' style={{ textDecoration: 'underline', color: 'white' }}>Forgot Password?</Link>
            </Box> */}
          </form>
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default LoginView;
