import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Fonts
let MuiTheme = createMuiTheme({
  typography: {
    allVariants: {
      color: 'white'
    },
    fontFamily: [
      '"Teko"',
      '"Roboto"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Teko',
      fontWeight: 900,
      textTransform: 'uppercase'
    },
    h2: {
      fontFamily: 'Teko',
      fontWeight: 900,
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: 'Teko',
      fontWeight: 900,
      textTransform: 'uppercase'
    },
    h4: {
      fontFamily: 'Teko',
      fontWeight: 600
    },
    h5: {
      fontFamily: 'Teko',
      fontSize: 30
    },
    h6: {
      fontFamily: 'Roboto'
    },
    body1: {
      fontFamily: 'Roboto',
      color: 'white',
      fontSize: 25
    },
    body2: {
      fontFamily: 'Teko',
      color: 'white',
      fontSize: 25
    },
    caption: {
      fontSize: 20
    }
  },
  direction: 'rtl',
  overrides: {
    MuiPaper: {
      root: {
        blackgroundColor: 'black'
      }
    },
    MuiTable: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiTableHead: {
      root: {
        color: 'white'
      }
    },
    MuiTableBody: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiTableCell: {
      root: {
        color: 'white'
      },
      head: {
        color: 'white'
      },
      body: {
        color: 'white'
      }
    },
    MuiAppBar: {
      root: {
        backgroundColor: 'black'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#EBB232',
      }
    },
    MuiListItem: {
      root: {
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: 'gray'
        }
      },
    },
    MuiListItemText: {
      root: {
        color: 'white'
      },
      primary: {
        fontSize: 20,
      },
      secondary: {
        color: 'white',
        fontSize: 18,
        lineBreak: 'strict'
      }
    },
    MuiDialogContentText: {
      root: {
        color: 'white',
        fontFamily: 'Teko'
      }
    }
  },
  palette: {
    primary: {
      main: '#EBB232'
    },
    secondary: {
      main: '#000080'
    }
  }
});

// Responsive Font Sizes Helper
MuiTheme = responsiveFontSizes(MuiTheme);


export default MuiTheme;
