import React, {createContext, useReducer, useEffect} from 'react';
import { Reducer, InitialGiveawayState, GiveawayState } from './reducer';
import server from '../../config/server';

interface ContextProps extends GiveawayState {
  fetchGiveaways: () => Promise<void>;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const GiveawayStore = createContext({} as ContextProps);

export const GiveawayStoreProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialGiveawayState);

  useEffect(() => {
    (async () => {
      await fetchGiveaways();
    })();
  }, []);

  async function fetchGiveaways() {
    const { data } = await server.get('/giveaways/active');
    dispatch({ type: 'setGiveaways', payload: data.data });
  }

  return (
    <GiveawayStore.Provider value={{ ...state, fetchGiveaways }}>
      { children }
    </GiveawayStore.Provider>
  );
};

