import React, {useEffect, useState} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AxiosServer from '../../../config/server';
import {User} from '../../../types/User';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

import StyledButton from '../../../components/StyledButton/StyledButton';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import Typography from '@material-ui/core/Typography';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormErrorResponse from "../../../components/FormError/FormErrorResponse";

interface IProps {
  visible: boolean;
  onClose: Function;
  user?: User;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    dialog: {
      minWidth: '40%'
    },
    formControl: {
      marginBottom: theme.spacing(2)
    },


  })
);

const ProfileModel: React.FC<IProps> = ({ visible, onClose, user }) => {
  const classes = useStyles();
  const [response, setResponse] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [profileState, setProfileState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (user) {
      console.log(user);
      setProfileState({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone
      });
    }
  }, [visible]);

  const validateSave = () => {
    const errors: string[] = [];
    if (profileState.firstName.length === 0) errors.push('First Name cannot be empty');
    if (profileState.lastName.length === 0) errors.push('Last Name cannot be empty');
    if (profileState.phone.length === 0) errors.push('Phone cannot be empty');
    if (profileState.email.length === 0) errors.push('Email cannot be empty');
    setValidated(true);
    return errors;
  };

  const handleSaveProfile = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateSave();
    if (errors.length !== 0) {
      setFormErrors(errors);
      return;
    }

    if (user) {
      try {
        const { data } = await AxiosServer.put(`/admin/users/${user.id}`, {
          ...profileState,
          email: profileState.email.trim().toLowerCase(),
          phone: profileState.phone.replace(/\D+/g, '')
        });
        setResponse(data.message);
        setTimeout(() => {
          onClose();
        }, 250);
      } catch (e) {
        setResponse(e.response.data.message);
      }
    }
  };

  const handleResetStripeResetClick = async () => {
    if (window.confirm('Are you sure you wish to reset their stripe profile? This cannot be undone.')) {
      if (user.stripeProfile) {
        try {
          const { data } = await AxiosServer.get(`/admin/users/${user.id}/stripe-subscription-reset`);
          setResponse('Stripe subscription reset, user can now subscribe. Please delete subscription from Stripe web portal.');
        } catch (e) {
          setResponse(e.response.data.message);
        }
      } else {
        setResponse('User does not have a stripe profile');
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileState({ ...profileState, [event.target.name]: event.target.value });
  };

  return (
    <div className={classes.root}>
      <StyledDialog open={visible} className={classes.dialog} onClose={() => onClose()}>
        <DialogTitle>Modify User</DialogTitle>
        <DialogContent>
          { formErrors.length > 0 && <Box my={2}><FormErrorResponse formErrors={formErrors}/></Box> }
          <form>
            <FormControl fullWidth>
              <StyledTextField
                name='firstName'
                label='First Name'
                fullWidth
                className={classes.textField}
                onChange={handleInputChange}
                value={profileState.firstName}
                variant='outlined'
                error={profileState.firstName === '' && validated}
                helperText={profileState.firstName === '' && validated ? 'Cannot be empty' : ''}
              />
            </FormControl>
            <FormControl fullWidth>
              <StyledTextField
                name='lastName'
                label='Last Name'
                fullWidth
                className={classes.textField}
                onChange={handleInputChange}
                value={profileState.lastName}
                variant='outlined'
                error={profileState.lastName === '' && validated}
                helperText={profileState.lastName === '' && validated ? 'Cannot be empty' : ''}
              />
            </FormControl>
            <FormControl fullWidth>
              <StyledTextField
                name='email'
                label='Email'
                fullWidth
                className={classes.textField}
                onChange={handleInputChange}
                value={profileState.email}
                variant='outlined'
                error={profileState.email === '' && validated}
                helperText={profileState.email === '' && validated ? 'Cannot be empty' : ''}
              />
            </FormControl>
            <FormControl fullWidth>
              <StyledTextField
                name='phone'
                label='Phone'
                fullWidth
                className={classes.textField}
                onChange={handleInputChange}
                value={profileState.phone}
                variant='outlined'
                error={profileState.phone === '' && validated}
                helperText={profileState.phone === '' && validated ? 'Cannot be empty' : ''}
              />
            </FormControl>
          </form>
        </DialogContent>
        <Box m={2} textAlign={'center'}>
          <Typography variant='body2'>{response}</Typography>
        </Box>
        <DialogActions>
          <StyledButton onClick={handleSaveProfile}>Save</StyledButton>
          <StyledButton onClick={handleResetStripeResetClick}>Rest Stripe</StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default ProfileModel;
