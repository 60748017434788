import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Promotion } from '../../../types/Promotion';
import { useHistory, useParams } from 'react-router-dom';
import server from '../../../config/server';
import { AxiosResponse } from 'axios';
import { FormControl, Grid, Toolbar, Typography, NativeSelect, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import StyledButton from '../../../components/StyledButton/StyledButton';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
        },
        toolbar: {
        borderBottom: '1px solid rgba(0,0,0,.15)'
        },
        flex: {
        flexGrow: 1
        },
        tableContainer: {
        marginTop: theme.spacing(4)
        },
        form: {
        marginTop: theme.spacing(4)
        },
        textField: {
        marginBottom: theme.spacing(2)
        },
        progress: {
        marginLeft: theme.spacing(2)
        },
        actionContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
        },
        itemToolbar: {
        borderTop: '1px solid rgba(0,0,0,.15)',
        },
        button: {
        marginRight: theme.spacing(2)
        },
        datePickerFields: {
        marginRight: theme.spacing(2),
        '& label': {
            fontFamily: 'Roboto'
        },
        '& input': {
            color: 'white'
        },
        '& fieldset': {
            borderColor: 'white',
            color: 'white',
        },
        '& .MuiButtonBase-root': {
            color: 'white'
        }
        },
        responseContainer: {
        marginTop: theme.spacing(2)
        },
        formControl: {
        marginBottom: theme.spacing(2)
        }
    })
);

interface IState {
    giveawayType: string;
    startDate: string;
    endDate: string;
    hasMaxEntries: boolean;
    multiplier: number;
}

interface IProps {
    promotion: Promotion;
    onRemove: Function;
}

interface ParamsState {
    promotionId: string;
}

const EditPromotionView: React.FC<IProps> = ({ promotion, onRemove}) => {
    const classes = useStyles();
    const history = useHistory();
    const { promotionId } = useParams<ParamsState>()
    const [formState, setFormState] = useState<IState>({
        giveawayType: '',
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
        hasMaxEntries: false,
        multiplier: 1
    });

    const [responseState, setResponseState] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        server.get(`/admin/promotions/${promotionId}`)
            .then((response: AxiosResponse) => {
                const promotion = response.data.promotion as Promotion;
                console.log(promotion);
                setFormState({
                    ...formState,
                    giveawayType: promotion.giveawayType,
                    startDate: new Date(promotion.startDate).toISOString(),
                    endDate: new Date(promotion.endDate).toISOString(),
                    hasMaxEntries: promotion.hasMaxEntries,
                    multiplier: promotion.multiplier
                });
            }).catch((error) => console.log(error));
    }, []);

    const handleTypeChange = (event: React.ChangeEvent<{value: string | unknown}>) => {
        const value = event.target.value as string;
        setFormState({...formState, giveawayType: value});
    };

    const handleDateChange = (field: string) => {
        return (date: Date | null) => {
            if (date) {
                date.setHours(0,0,0,0);
            }
            setFormState({ ...formState, [field]: date});
        };
    };

    const handleMaxEntriesChange = (event: React.ChangeEvent<{value: boolean | unknown}>) => {
        const value = event.target.value as boolean;
        setFormState({ ...formState, hasMaxEntries: value });
    };

    const handleMultiplierChange = (event: React.ChangeEvent<{ value: number | unknown }>) => {
        const value = event.target.value as number;
        setFormState({ ...formState, multiplier: value });
    };
    // remove promotion
    const handleRemovePromotion = async () => {
        try {
            setIsLoading(true);
            await server.delete(`/admin/promotions/${promotionId}`);
            setIsLoading(false);
            // success
            history.push('/admin/manage-promotions');
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    // save promotion details
    const handleSubmit = async () => {
        if (new Date(formState.startDate) > new Date(formState.endDate)) {
            alert('The start date must be before the end date');
        } else if (formState.multiplier < 1) {
            alert('The multiplier may not be 0');
        } else {
            try {
                setIsLoading(true);
                await server.patch(`/admin/promotions/${promotionId}`, formState);
                setIsLoading(false);
                // success
                history.push('/admin/manage-promotions');
            } catch (e) {
                setIsLoading(false);
                console.log(e.response.data.message);
            }
        }
    };

    return (
        <div className={classes.root}>
            <Toolbar className={classes.toolbar} variant="dense">
                <Typography variant={'body2'}>Editing: { formState.giveawayType }</Typography>
                <div className={classes.flex}/>
                <StyledButton className={classes.button} variant='outlined' size='small' onClick={handleSubmit}>Save</StyledButton>
                <StyledButton className={classes.button} variant='outlined' size='small' onClick={handleRemovePromotion}>Delete</StyledButton>
            </Toolbar>
            <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl className={classes.formControl} fullWidth>
                            <NativeSelect
                                variant='outlined'
                                value={formState.giveawayType}
                                onChange={handleTypeChange}
                                inputProps={{name: 'type', id: 'type'}}
                                input={<StyledSelect/>}
                            >
                                <option value={''}></option>
                                <option value={'beginner'}>Beginner</option>
                                <option value={'intermediate'}>Intermediate</option>
                                <option value={'expert'}>Expert</option>
                                <option value={'modern'}>Modern Classic</option>

                            </NativeSelect>
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label="Start Date"
                                inputVariant="outlined"
                                autoOk={true}
                                value={formState.startDate}
                                onChange={handleDateChange('startDate')}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                className={classes.datePickerFields}
                            />
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label="End Date"
                                inputVariant="outlined"
                                autoOk={true}
                                value={formState.endDate}
                                onChange={handleDateChange('endDate')}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                autoFocus={true}
                                className={classes.datePickerFields}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <StyledTextField
                                name='multiplier'
                                label='Multiplier'
                                onChange={handleMultiplierChange}
                                value={formState.multiplier}
                                variant='outlined'
                                
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <NativeSelect
                                variant='outlined'
                                value={formState.hasMaxEntries}
                                onChange={handleMaxEntriesChange}
                                inputProps={{name: 'isMaxEntries', id: 'isMaxEntries'}}
                                input={<StyledSelect/>}
                            >
                                <option value={'false'}>Max Entries Disabled</option>
                                <option value={'true'}>Max Entries Enabled</option>

                            </NativeSelect>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>

        </div>
    )
}

export default EditPromotionView;
