import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import  Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Giveaway } from '../../../types/Giveaway';
import AxiosServer from '../../../config/server';
import StyledButton from '../../../components/StyledButton/StyledButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)',
      '& a': {
        textDecoration: 'none'
      }
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {},
    icon: {
      fontSize: 24
    }
  })
);


const ManageGiveawaysView: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = useState([]);
  useEffect(() => {
    ( async () => {
      try {
        const { data } = await AxiosServer.get('/admin/giveaways');
        setState(data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleToggleGiveaway = (giveawayId: string) => async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await AxiosServer.post(`/admin/giveaways/${giveawayId}/toggle`);
      const { data } = await AxiosServer.get('/admin/giveaways');
      setState(data);
    } catch (e) {

    }
  };

  const tableBody = (data: Giveaway[]) => {
    return data.map((item: Giveaway) => (
      <TableRow key={item.id}>
        <TableCell>
          <Typography variant='body1'>{item.title}</Typography>
          <Typography variant='caption'>Created at: {new Date(item.createdAt).toISOString().substr(0, 16)}</Typography>
        </TableCell>
        <TableCell>
          {new Date(item.startDate).toDateString()}
        </TableCell>
        <TableCell>
          {new Date(item.endDate).toDateString()}
        </TableCell>
        <TableCell>{item.items.length}</TableCell>
        <TableCell>
          <StyledButton onClick={handleToggleGiveaway(item.id)}>{ item.active ? 'Deactivate' : 'Activate'}</StyledButton>
        </TableCell>
        <TableCell>
          <Link to={`/admin/manage-giveaways/g/${item.id}`}>
            <StyledButton>Edit</StyledButton>
          </Link>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <Grid item xs={12} md={10}>
          <div style={{ textAlign: 'right'}}>
            <Link to='/admin/manage-giveaways/create'>
              <StyledButton>
                Create New Giveaway
              </StyledButton>
            </Link>
          </div>
          <Paper className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                { state.length > 0 ? tableBody(state) : (
                  <Box alignItems='center' padding={2}>
                    <Typography variant='h2'>No Giveaways</Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageGiveawaysView;
