import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import server from '../../config/server';
import StyledButton from '../StyledButton/StyledButton';


interface Props {
  onUploadComplete: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    wrapper: {
      position: 'relative'
    },
    button: {

    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);



const FileUploader: React.FC<Props> = ({ onUploadComplete }) => {
  const classes = useStyles();
  const inputId = 'label__' + Math.floor(Math.random() * 100000);

  const inputRef = React.createRef<HTMLInputElement>();
  const [isLoading, setIsLoading] = useState(false);


  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', inputRef.current!.files![0]);
      const { data } = await server({
        method: 'post',
        url: '/upload-file',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      onUploadComplete(data.Location);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <StyledButton onClick={handleClick} disabled={isLoading} className={classes.button} variant='outlined'>Select File</StyledButton>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      <div style={{ display: 'none' }}>
        <input
          type='file'
          id={inputId}
          ref={inputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FileUploader;
