// React
import React from 'react';
import { AuthStoreProvider } from './hooks/useAuth/context';
import { EntryStoreProvider } from './hooks/useEntries/context';
import { GiveawayStoreProvider } from './hooks/useGiveaways/context';

// Router Packages
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

// Stylesheet
import './App.css';

// MUI Integration
import { ThemeProvider } from '@material-ui/core/styles';
import MuiTheme from './utils/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';

// Components
import Navbar from './components/Nav/Navbar';
import PrivateRoute from './components/Routing/PrivateRoute';
import LoginView from './views/Login/LoginView';
import LogoutView from './views/LogoutView';
import HomeView from './views/Home/HomeView';
import GiveawayView from './views/Giveaway/GiveawayView';
import AdminRootView from './views/Admin/AdminRootView';
import RegisterView from './views/Register/RegisterView';
import AdminRoute from './components/Routing/AdminRoute';
import SubscriptionView from './views/Subscription/SubscriptionView';

import AddSubscriptionView from './views/Subscription/AddSubscriptionView';
import EntriesView from './views/Entries/EntriesView';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import OfficialRulesView from './views/Legal/OfficialRulesView';

import TermsAndConditionsView from './views/Legal/TermsAndConditionsView';
import PrivacyPolicy from './views/Legal/PrivacyPolicy';
import AccountManageView from './views/Account/AccountManageView';
import ForgotPasswordView from './views/Forgot/ForgotPasswordView';
import ForgotPasswordResetView from './views/Forgot/ForgotPasswordResetView';
import SubmissionsView from './views/Submissions/SubmissionsView';
import ContactView from './views/Contact/ContactView';
import DiscordAuthorizationView from './views/Discord/DiscordAuthorizationView';
import Footer from './components/Footer/Footer';
import AfterRegistrationLanding from './views/AfterRegistrationLanding/AfterRegistrationLanding';
import MeetupView from './views/Meetup/MeetupView';
import NotificationBanner from "./components/NotificationBanner/NotificationBanner";
import { ShopifyStoreRedirectView } from './views/ShopifyStore/ShopifyStoreRedirectView'
import { TwistedRoadRedirectView } from './views/TwistedRoad/TwistedRoadRedirectView'

// Analytics
ReactGA.initialize('UA-156168022-1');

// Views
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App: React.FC = () => {
  ReactGA.pageview(window.location.pathname);
  return (
    <React.Fragment>
      <CssBaseline />
      <GiveawayStoreProvider>
        <AuthStoreProvider>
          <EntryStoreProvider>
            <ThemeProvider theme={MuiTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router history={history}>
                  <ScrollToTop />
                  <Navbar />
                  <main>
                    {/* <NotificationBanner /> */}

                    {/* <Route path='/register' component={RegisterView} />
                    <Route path='/official-rules' component={OfficialRulesView}/>
                    <Route path='/terms-and-conditions' component={TermsAndConditionsView} />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/forgot' exact component={ForgotPasswordView} />
                    <Route path='/forgot/verify' exact component={ForgotPasswordResetView} />
                    <Route path='/contact' exact component={ContactView} />
                    <Route path='/discord' exact component={DiscordAuthorizationView} />
                    <Route path='/giveaway' component={GiveawayView} />
                    <Route path='/welcome' component={AfterRegistrationLanding} />
                    <PrivateRoute path='/meetup' component={MeetupView} />
                    */}



                    {/* 
                    <PrivateRoute path='/entries' exact component={EntriesView} />
                    <PrivateRoute path='/submission' component={SubmissionsView} />
                    <PrivateRoute path='/subscribe' exact component={SubscriptionView} />
                    <PrivateRoute path='/subscribe/add' component={AddSubscriptionView} />
                    <PrivateRoute path='/store' component={ShopifyStoreRedirectView} />
                    <PrivateRoute path='/twistedroad' component={TwistedRoadRedirectView} /> */}

                    <Route path='/' exact component={LoginView} />
                    <Route path='/login' component={LoginView} />
                    <Route path='/logout' component={LogoutView} />
                    <PrivateRoute path='/account' component={AccountManageView} />
                    <AdminRoute path='/admin' component={AdminRootView} />

                    <Route path="*" component={LoginView} />
                  </main>
                  {/* <Footer /> */}
                </Router>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </EntryStoreProvider>
        </AuthStoreProvider>
      </GiveawayStoreProvider>
    </React.Fragment>
  );
};

export default App;
